import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  Inject,
  inject,
  OnInit,
  signal,
  viewChild,
} from "@angular/core";
import { IRadio } from "@app/shared/interfaces/roam-radio.inteface";
import { paymentMethodCards, PaymentMethodMessage } from "@app/shared/components/payment/shared/payment.const";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { PaymentMethodCreditCardComponent } from "../payment-method-credit-card/payment-method-credit-card.component";
import { PaymentMethodService } from "@app/shared/services/payment-method.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { catchError, map, of, switchMap } from "rxjs";
import { RoamToastrService } from "@app/pages/task/+data-access";

@Component({
  selector: "app-add-payment-method",
  templateUrl: "./add-payment-method.component.html",
  styleUrls: ["./add-payment-method.component.scss"],
})
export class AddPaymentMethodComponent implements OnInit {
  public paymentMethods: IRadio[] = paymentMethodCards;
  public selectedMethod: string = "card";
  readonly isSubmit = signal<boolean>(false);
  credit = viewChild.required<PaymentMethodCreditCardComponent>("creditPayment");

  private destroyRef = inject(DestroyRef);
  private service = inject(PaymentMethodService);
  private toastr = inject(RoamToastrService)

  constructor(
    private dialogRef: MatDialogRef<AddPaymentMethodComponent>,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: {clientSecret: string}
  ) {
    this.setupClient();
    
  }

  ngOnInit(): void {
    this.cdr.detectChanges();
  }

  private setupClient(): void {
    this.service
      .stripeSetupIntent()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(resp => {
        this.credit().elementsOptions.clientSecret = resp.clientSecret;
      });
  }

  public saveMethodPayment(): void {
    if(this.credit().form.valid) {
      this.isSubmit.set(true);
      this.sendCard();
    } else {
      this.credit().form.get('cardholder')?.markAsTouched();
    }
  }

  private confirmSetup() {
    return this.credit().stripe.confirmSetup({
      elements: this.credit().paymentElement.elements,
      confirmParams: {
        payment_method_data: {
          billing_details: {
            name: this.credit().form.get('cardholder')?.value
          }
        }
      },
      redirect: 'if_required'
    }).pipe((
      map((result: any) => {
        if (result.error) throw result.error;
        if (!result.setupIntent) throw new Error('SetupIntent is missing in result');
        return result;
      })
    ))
  }

  private sendCard(): void {
    this.confirmSetup().pipe(
      switchMap(result => this.submitPaymentMethod(result.setupIntent.id, result.setupIntent.payment_method)),
      catchError(error => {
        this.isSubmit.set(false);
        return of(null);
      })
    ).subscribe();
    
  }

  private submitPaymentMethod(id: string, paymentMethod: string) {
    const payload = { setupIntentId: id, paymentMethodId: paymentMethod };
  
    return this.service.createPaymentMethod(payload).pipe(
      map(resp => {
        this.dialogRef.close(resp);
        this.isSubmit.set(false);
        this.toastr.success(PaymentMethodMessage.successNewPaymentMethod)
        return resp;
      }),
      catchError(error => {
        return of(null);
      })
    );
  }


}
